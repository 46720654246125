// State
export const state = () => ({
    loading: false,
    menu: [],
    currency: [],
    languages: [],
    language: 'en-US',
    countries: [],
    exchangeRate: [],
    slider: [],
    isLogin: false,
    locale: 'th-TH',
    defaultCurrency: null,
    defaultCountry: null,
    displayPopup: true,
    downloadInterval: false,
    supportCountryAndLanguage: [],
    defaultSupportedLanguage: [],
    changeLanguageLoading: false,
    globalFlag: 'GO',
    operationSetting: {},
    logo: {},
    cryptoWalletAddressList: [],
    defaultWebsiteData: {},
    // cancel token
    cancelToken: {},
})

// Actions
export const actions = {
    // Currency
    async currency({ commit }, params) {
        try {
            await this.$axios
                .get(`/currency`, {
                    params,
                })
                .then((response) => {
                    commit('setCurrency', response.data)
                })
        } catch (error) {
            return false
        }
    },
    // Get countries - WING CODE
    async countries({ commit }) {
        try {
            await this.$axios.get(`/country`).then((response) => {
                commit('setCountries', response.data)
            })
        } catch (error) {
            return false
        }
    },
    toggleIsLogin({ commit }, status) {
        commit('setIsLogin', status)
    },
    locale({ commit }, lang) {
        commit('locale', lang)
    },
    async getDefaultCurrency({ rootState, dispatch }, params) {
        /**
		  How to the default language is set; Sort by the highest to lowest priority.
		  1. Highest priority, check if the browser cookie has set the language. Use that language to fetch language strings.
		  2. Check supported languages, if only one is available, use that one as the default language.
		  3. Get the browser language by navigator.language. Check if the language is matched by some supported languages, use that language as the default.
		  4. Find the the language which is active and is default. Use that one as the default language.
		  5. If all the above conditions are false, use the first supported languages as the default language.
		 */
        if (rootState.themes.settings.isInternational) {
            await dispatch('getDefaultCurrencyForInternational')
            return
        }
        await dispatch('getDefaultCurrencyForLao')
    },
    async getDefaultCurrencyForLao({ commit, dispatch }, params) {
        await this.$axios('currency/default', params)
            .then(async (data) => {
                let languageStringLanguage = null
                const {
                    currency: defaultCurrency,
                    country_code: defaultCountryCode,
                    country: defaultCountry,
                    domaintype: domainType,
                } = data?.headers

                const currency = this.$cookie.get('currency')
                const country = this.$cookie.get('country')
                const countryCode = this.$cookie.get('country_code')
                const localeInTheCookie = this.$cookie.get('wl_locale')
                /**
                 * Cookies explanation
                 * 1. global_reg is on register screen for default phone number
                 * 2. country is country
                 * 3. country_code is for each country flag
                 */

                // global registration
                this.$cookie.set('global_reg', defaultCountry)
                globalRegister(
                    this.$cookie,
                    commit,
                    domainType,
                    currency,
                    defaultCurrency,
                    country,
                    defaultCountry,
                    countryCode,
                    defaultCountryCode
                )

                let supportedLanguages = data?.data?.supported_languages

                // Get the supported country from supported languages
                const supportedCountryByToken = await dispatch(
                    'supportCountryAndLanguage'
                )

                const languages = findSupportLanguage(
                    supportedCountryByToken,
                    currency,
                    countryCode
                )

                if (languages) supportedLanguages = languages

                commit('defaultSupportedLanguage', supportedLanguages)

                // Use the locale stored in the cookie, if it exists, as the default language.
                // This is necessary during the initialization of website settings, as the Locale state
                // is set later in the process. Relying solely on cookies ensures that the locale has
                // been preserved across sessions.
                // If the locale in the cookie is active, apply it. Otherwise, fallback to the default.
                if (localeInTheCookie) {
                    // Validate if the cookie locale is still active; if so, use it, else fallback to default.
                    const locale = activeLocale(
                        commit,
                        dispatch,
                        supportedLanguages,
                        localeInTheCookie
                    )

                    if (locale) languageStringLanguage = locale
                }

                // After all the LOGIC above, if system Locale is still not set, then find the default one from supportedLanguages list
                if (!languageStringLanguage && supportedLanguages.length > 0) {
                    // If the support language has only One Language, just use that one as default
                    const defaultLanguage = defaultSupportLanguage(
                        dispatch,
                        supportedLanguages
                    )

                    if (defaultLanguage)
                        languageStringLanguage = defaultLanguage
                }

                if (languageStringLanguage) {
                    dispatch(
                        'themes/language-string/fetchLanguageStrings',
                        languageStringLanguage,
                        {
                            root: true,
                        }
                    )
                }
            })
            .catch((_) => {})
    },
    async getDefaultCurrencyForInternational(
        { commit, dispatch, rootState },
        params
    ) {
        let userPlatform = 'desktop'
        if (rootState.themes.settings.isMobile) {
            userPlatform = 'mobile'
        } else if (rootState.app.isApp.detected) {
            userPlatform = 'app'
        }

        await this.$axios('currency/default', {
            params,
            headers: {
                'x-platform': userPlatform,
            },
        })
            .then((data) => {
                /**
                 * Response Header:
                 * Domain-Type, Domain-Status, X-DOMAIN-NAME, X-AGENT-ID, X-CLIENT-IP,
                 * X-CLIENT-COUNTRY, X-CLIENT-CURRENCY, X-AGENT-TYPE,
                 * X-PLAYER-DOMAIN, X-FLOATING-ICON-DOMAIN, X-BANNER-ID,
                 * X-PLAYER-TYPE
                 */

                let defaultLanguageString = null

                // The essential response headers
                const defaultCurrency = data?.headers['x-client-currency']
                const domainType = data?.headers['domain-type']
                const defaultCountry = data?.headers['x-client-country']

                // To get the country code (The User Real Location)
                const findTheCountryFromTheCurrenciesList =
                    data.data.currencies.find(
                        (el) => el.code === defaultCurrency
                    )
                const defaultCountryCode = findTheCountryFromTheCurrenciesList
                    ? findTheCountryFromTheCurrenciesList.country_code
                    : 'GO'

                // All the essential existing cookies
                const cookieCurrency = this.$cookie.get('currency')
                const cookieCountry = this.$cookie.get('country')
                const cookieCountryCode = this.$cookie.get('country_code')
                const localeInTheCookie = this.$cookie.get('wl_locale')

                const supportedCurrenciesList = data.data.currencies

                // Set all essential response data into state, so we can use it everywhere in the website
                const defaultWebsiteData = {
                    recaptcha_status: data.data.recaptcha_status,
                    'domain-type': data?.headers['domain-type'],
                    'domain-status': data?.headers['domain-status'],
                    'x-domain-name': data?.headers['x-domain-name'],
                    'x-agent-id': data?.headers['x-agent-id'], // Frontend:: will never use this key
                    'x-client-ip': data?.headers['x-client-ip'], // Frontend:: will never use this key
                    'x-client-country': data?.headers['x-client-country'],
                    'x-client-currency': data?.headers['x-client-currency'],
                    'x-agent-type': data?.headers['x-agent-type'],
                    'x-player-domain': data?.headers['x-player-domain'],
                    'x-floating-icon-domain':
                        data?.headers['x-floating-icon-domain'],
                    'x-banner-id': data?.headers['x-banner-id'],
                    'x-player-type': data?.headers['x-player-type'],
                }
                commit('setDefaultWebsiteData', defaultWebsiteData)

                // To set all the response header into Cookie
                setDefaultCookieHeader(this.$cookie, data.headers)

                // To set the default flag country
                this.$cookie.set('global_reg', defaultCountry)
                // Set domain type either Main Website or Agent Website
                commit(
                    'base-settings/setConfiguration',
                    {
                        domain_type: domainType,
                    },
                    {
                        root: true,
                    }
                )

                // Set default currencies list into supportCountryAndLanguage
                commit(
                    'supportCountryAndLanguage',
                    supportedCurrenciesList.map((el) => {
                        return {
                            ...el,
                            currency_code: el.code,
                        }
                    })
                )

                // SECTION I: Check cookie value and set cookie value for the Website Default Configuration
                // Check if the currency is not existing in the cookie, then set it into cookie
                if (!cookieCurrency) {
                    this.$cookie.set('currency', defaultCurrency)
                    commit('setDefaultCurrency', defaultCurrency)
                } else {
                    // Check if the currency is still existing
                    const findCookieCurrencyFromList =
                        supportedCurrenciesList.find(
                            (el) => el.code === cookieCurrency
                        )
                    if (!findCookieCurrencyFromList) {
                        this.$cookie.set('currency', defaultCurrency)
                        commit('setDefaultCurrency', defaultCurrency)
                    } else {
                        commit('setDefaultCurrency', cookieCurrency)
                    }
                }

                // Check if country is not existing in the cookie than set the response default Country into cookie
                if (!cookieCountry) {
                    this.$cookie.set('country', defaultCountry)
                }
                commit('setDefaultCountry', cookieCountry || defaultCountry)

                if (!cookieCountryCode) {
                    this.$cookie.set('country_code', defaultCountryCode)
                    commit('setGlobalFlag', defaultCountryCode)
                } else {
                    const findCookieCountryFromList =
                        supportedCurrenciesList.find(
                            (el) => el.code === cookieCurrency
                        )
                    if (!findCookieCountryFromList) {
                        this.$cookie.set('country_code', defaultCountryCode)
                        commit('setGlobalFlag', defaultCountryCode)
                    } else {
                        commit('setGlobalFlag', cookieCountryCode)
                    }
                }

                // If there is only one supported country and language list, then set that languages as default
                if (supportedCurrenciesList.length === 1) {
                    this.$cookie.set(
                        'country_code',
                        supportedCurrenciesList[0]?.country_code
                    )
                    commit(
                        'setGlobalFlag',
                        supportedCurrenciesList[0]?.country_code
                    )
                }

                // SECTION II: Find the match language (locale) to fetch language-strings
                // Find the supported language in the matching currency (Selected currency)
                const theSupportedLanguages = supportedCurrenciesList.find(
                    (el) =>
                        findMatchSupportedCountryByCurrencyFunc(
                            el,
                            cookieCurrency,
                            cookieCountryCode
                        )
                )

                // Check if the locale cookie is already set, then recheck again if it is still active
                if (localeInTheCookie && theSupportedLanguages) {
                    const checkIfTheCookieLocaleIsStillActive =
                        theSupportedLanguages.supported_languages.find(
                            (locale) =>
                                locale.language === localeInTheCookie &&
                                locale.is_active
                        )
                    if (checkIfTheCookieLocaleIsStillActive) {
                        commit('locale', localeInTheCookie)
                        dispatch(
                            'base-settings/updateLocaleAndSetCookie',
                            localeInTheCookie,
                            {
                                root: true,
                            }
                        )
                        defaultLanguageString = localeInTheCookie
                    }
                }

                // Check if the matching language string is not set yet, then find the most default one from API response
                if (!defaultLanguageString) {
                    const defaultLanguage = defaultSupportLanguage(
                        dispatch,
                        theSupportedLanguages.supported_languages
                    )
                    if (defaultLanguage) defaultLanguageString = defaultLanguage
                }

                // Get the language string based on the selected language
                if (defaultLanguageString)
                    dispatch(
                        'themes/language-string/fetchLanguageStrings',
                        defaultLanguageString,
                        {
                            root: true,
                        }
                    )
            })
            .catch((_) => {})
    },
    async supportCountryAndLanguage({ commit, state }, params = {}) {
        if (
            state.supportCountryAndLanguage.length !== 0 &&
            params?.fetch !== true
        )
            return state.supportCountryAndLanguage
        try {
            const response = await this.$axios.get(
                '/country/supported-language'
            )
            if (response.data?.length === 1) {
                this.$cookie.set('country_code', response.data[0]?.country_code)
                commit('setGlobalFlag', response.data[0]?.country_code)
            }
            commit('supportCountryAndLanguage', response.data)
            return response.data
        } catch (error) {}
    },
    async operationSetting({ commit, state, rootState }, currency) {
        try {
            const response = await this.$axios.get(
                `/website-setting/operation-setting/${
                    currency || state.defaultCurrency
                }`
            )
            const data = response.data
            commit('operationSetting', response.data)
            //  execute only inter wl
            if (rootState.themes.settings.isInternational) {
                commit(
                    'themes/deposit/depositFlow',
                    {
                        deposit_type: data.deposit_type,
                        is_bank_transfer_enabled:
                            data?.payment_type_settings?.bank_transfer || false,
                        is_third_party_payment_enabled:
                            data?.payment_type_settings?.third_party || false,
                        is_crypto_payment_enabled:
                            data?.payment_type_settings?.crypto_currency ||
                            false,
                        reg_flow: data.reg_flow,
                    },
                    {
                        root: true,
                    }
                )
            }
        } catch (error) {
            return false
        }
    },
    async getCryptoWalletAddressList({ commit }, params) {
        try {
            const response = await this.$axios('/currency/crypto', {
                params,
            })
            commit('setCryptoWalletAddressList', response.data)
        } catch (error) {}
    },
}

// Mutations
export const mutations = {
    loading: (state, v) => {
        state.loading = v
    },
    setCurrency: (state, v) => {
        state.currency = v
    },
    setCountries: (state, v) => {
        state.countries = v
    },
    setMenu: (state, v) => {
        const promotion = {
            title: {
                'en-US': 'Promotion',
                'lo-LA': 'ໂປຮໂມຊັນ',
                'th-TH': 'โปรโมชั่น',
            },
            to: '/promotion',
            game_type_code: 'OT',
        }
        state.menu = [...v, promotion]
        // state.menu = v.concat(state.menu)
    },
    setExchangeRate: (state, v) => {
        state.exchangeRate = v
    },
    setIsLogin: (state, v) => {
        state.isLogin = v
    },
    locale: (state, v) => {
        state.locale = v
    },
    setDefaultWebsiteData: (state, v) => {
        state.defaultWebsiteData = v
    },
    setCancelToken: (state, data) => {
        const oldData = {
            ...state.cancelToken,
        }
        state.cancelToken = {
            ...oldData,
            ...data,
        }
    },
    setDefaultCurrency: (state, data) => {
        state.defaultCurrency = data
    },
    setDefaultCountry: (state, data) => {
        state.defaultCountry = data
    },
    setGlobalFlag: (state, data) => {
        state.globalFlag = data
    },
    setDisplayPopup: (state, data) => {
        state.displayPopup = data
    },
    setDownloadInterval: (state, data) => {
        state.downloadInterval = data
    },
    supportCountryAndLanguage: (state, data) => {
        state.supportCountryAndLanguage = data
    },
    defaultSupportedLanguage: (state, data) => {
        state.defaultSupportedLanguage = data
    },
    changeLanguageLoading: (state, data) => {
        state.changeLanguageLoading = data
    },
    operationSetting: (state, data) => {
        state.operationSetting = data
    },
    setCryptoWalletAddressList: (state, data) => {
        state.cryptoWalletAddressList = data
    },
}

// Getters
export const getters = {
    currency: (state) => {
        return state.currency
    },
    cryptoCurrencies: (state) => {
        return state?.currency?.filter((el) => el.type === 2)
    },
    currencyIsExchange: (state) => {
        return state.currency.filter((o) => o.is_exchange)
    },
    countries: (state) => {
        return state.countries
    },
    floatingIcons: (state) => {
        return state.floatingIcons
    },
    language: (state) => {
        return state.language
    },
    languages: (state) => {
        return state.languages.filter((o) => o.is_active)
    },
    locale: (state) => {
        return state.locale
    },
    exchangeRate: (state) => (from, to) => {
        let filter = {
            rate: 0,
        }
        filter = state.exchangeRate.find(
            (o) => o.from_currency === from && o.to_currency === to
        )
        return filter
    },
    isWebsiteMaintenance: (state) => (currency) => {
        const findMatchedCurrency = state.supportCountryAndLanguage.find(
            (el) => el.code === currency
        )
        if (findMatchedCurrency) return findMatchedCurrency.website_maintenance
        return {
            status: false,
            description: '',
        }
    },
    isCurrencySuspendedForRegistration: (state) => (currency) => {
        if (state.operationSetting?.code === currency) {
            return state.operationSetting?.suspend_reg
        } else {
            return true
        }
    },
}

// eslint-disable-next-line no-unused-vars
const globalRegister = (
    cookie,
    commit,
    domainType,
    currency,
    defaultCurrency,
    country,
    defaultCountry,
    countryCode,
    defaultCountryCode
) => {
    if (domainType) {
        commit(
            'base-settings/setConfiguration',
            {
                domain_type: domainType,
            },
            {
                root: true,
            }
        )
    }
    // If currency is not exist, than set currency
    if (!currency) {
        cookie.set('currency', defaultCurrency)
        commit('setDefaultCurrency', defaultCurrency)
    } else {
        commit('setDefaultCurrency', currency)
    }

    if (!country) {
        cookie.set('country', defaultCountry)
        commit('setDefaultCountry', defaultCountry)
    }

    if (!countryCode) {
        cookie.set('country_code', defaultCountryCode)
        commit('setGlobalFlag', defaultCountryCode)
    } else {
        commit('setGlobalFlag', countryCode)
    }
}

const findSupportLanguage = (
    supportedCountryByToken,
    currency,
    countryCode
) => {
    if (supportedCountryByToken?.length && currency) {
        const findMatchSupportedCountryByCurrency =
            supportedCountryByToken.find((e) =>
                findMatchSupportedCountryByCurrencyFunc(
                    e,
                    currency,
                    countryCode
                )
            )
        if (findMatchSupportedCountryByCurrency) {
            return findMatchSupportedCountryByCurrency.supported_languages
        }
    }
}

const findMatchSupportedCountryByCurrencyFunc = (el, currency, countryCode) => {
    if (countryCode) {
        if (
            (el.currency_code === currency || el.code === currency) &&
            el.country_code === countryCode
        ) {
            return true
        }
        return false
    } else if (el.currency_code === currency || el.code === currency) {
        return true
    }

    return false
}

const activeLocale = (
    commit,
    dispatch,
    supportedLanguages,
    localeInTheCookie
) => {
    const isCookieLocaleActive = supportedLanguages.find(
        (locale) =>
            locale.language === localeInTheCookie && locale.is_active === true
    )
    if (isCookieLocaleActive?.is_active) {
        commit('locale', localeInTheCookie)
        dispatch('base-settings/updateLocaleAndSetCookie', localeInTheCookie, {
            root: true,
        })

        return localeInTheCookie
    }
}

const defaultSupportLanguage = (dispatch, supportedLanguages) => {
    let matchedLocale = null
    if (supportedLanguages.length === 1) {
        matchedLocale = supportedLanguages[0].language
    } else {
        // Check if the browser language is matched to one of the supported languages
        const browserLanguage = navigator?.language
        const browserPrefixLanguageTag = browserLanguage?.substring(0, 2)

        // Find the language which starting with the same browser Prefix Language Tag
        let matchBrowserLanguage = null
        if (browserPrefixLanguageTag) {
            matchBrowserLanguage = findMatchBrowserLanguage(
                supportedLanguages,
                browserPrefixLanguageTag
            )
        }

        // Find the browser Prefix Language is match, then use only browser language
        if (matchBrowserLanguage) {
            matchedLocale = matchBrowserLanguage.language
        } else {
            // If browser doesn't supported navigator.language, then use the default and active one from BO
            const findIsActiveLanguage = supportedLanguages.find(
                (el) => el.is_active && el.is_default
            )
            if (findIsActiveLanguage) {
                matchedLocale = findIsActiveLanguage.language
            } else {
                matchedLocale = supportedLanguages[0].language
            }
        }
    }
    dispatch('base-settings/updateLocaleAndSetCookie', matchedLocale, {
        root: true,
    })

    return matchedLocale
}

const findMatchBrowserLanguage = (
    supportedLanguages,
    browserPrefixLanguageTag
) => {
    return supportedLanguages.find(
        ({ language, is_active: isActive }) =>
            isActive && language.includes(browserPrefixLanguageTag)
    )
}

const setDefaultCookieHeader = (cookie, header) => {
    const expiredIn10Days = 10 * 24 * 60 * 60
    if (header['x-agent-id']) cookie.set('x-agent-id', header['x-agent-id'])
    if (header['x-floating-icon-domain'])
        cookie.set('x-floating-icon-domain', header['x-floating-icon-domain'])
    if (header['x-banner-id'])
        cookie.set('x-banner-id', header['x-banner-id'], {
            path: '/',
            maxAge: expiredIn10Days,
        })
    if (header['x-player-type'])
        cookie.set('x-player-type', header['x-player-type'], {
            path: '/',
            maxAge: expiredIn10Days,
        })
}

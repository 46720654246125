// State
export const state = () => ({
	loading: false,
	withdrawalExistingData: {}
})

// Actions
export const actions = {
	// Get check withdrawal
	async checkExistWithdrawal({ commit }) {
		this.commit('settings/setCancelToken', { existWithdraw: this.$axios.CancelToken.source() })
		try {
			const response = await this.$axios
				.get('/withdrawals/check/is-exists', {
					cancelToken: this.state.settings.cancelToken.existWithdraw.token
				})
			commit('setWithdrawalExistingData', response.data)
			return response.data
		} catch (error) {
			return {}
		}
	},
	// Create withdraw
	async createWithdraw({ commit }, form) {
		commit('setLoading', true)
		try {
			const response = await this.$axios
				.post('/withdrawals', form)
			return response.data
		} catch (error) {

		} finally {
			commit('setLoading', false)
		}
	},
	// Cancel withdraw
	async cancelWithdraw({ commit }, id) {
		commit('setLoading', true)
		try {
			const response = await this.$axios
				.put('/withdrawals/cancel/' + id, {})
			return response.data
		} catch (error) {

		} finally {
			commit('setLoading', false)
		}

	},
	async createCryptoWithdrawal({ commit }, form) {
		commit('setLoading', true)
		try {
			const response = await this.$axios
				.post('/withdrawals/crypto', form)
			return response.data;
		} catch (error) {

		} finally {
			commit('setLoading', false)
		}
	},
}

// Mutations
export const mutations = {
	setLoading(state, data) {
		state.loading = data
	},
	setWithdrawalExistingData(state, data) {
		state.withdrawalExistingData = data
	}
}

// Getters
export const getters = {
}
// State
export const state = () => ({
  tempData: {},
  thirdPartyPayments: [],
  depositFlow: {},
  checkExist: {},
  checkDeposit: false,
  companyCryptoWallet: []
})

// Actions
export const actions = {
  async create({ commit }, data) {
    const form = { ...data }
    try {
      const response = await this.$axios
        .post(`/deposits`, form)
      commit('setTempData', response.data)
      return response.data;
    } catch (error) {
    }
  },
  async confirm({ commit }, id) {
    try {
      await this.$axios
        .put(`/deposits/${id}`, {})
      commit("checkDeposit", true)
      return true
    } catch (error) {
      return false;
    }
  },
  async revoke({ commit }, id) {
    try {
      await this.$axios
        .put(`/deposits/revoke/${id}`, {})
      commit("checkDeposit", false)
      return true;
    } catch (error) {
      return false;
    }

  },
  async checkExist({ commit }, _) {
    try {
      const response = await this.$axios
        .get(`/deposits/check/is-exists`)
      commit("checkExist", response.data)
      return response.data;
    } catch (error) {
      return null;
    }
  },
  async getThirdPartyPayments({ commit }, _) {
    try {
      const response = await this.$axios.get('third-party-payments/providers')
      commit('setThirdPartyPayments', response.data)
    } catch (error) {

    }
  },
  async depositFlow({ commit }, currency) {
    try {
      const response = await this.$axios.get(`website-setting/deposit-flow/${currency}`)
      commit('depositFlow', response.data)
      // commit('depositFlow', {
      // 	deposit_type: 2,
      // 	is_bank_transfer_enabled: true,
      // 	is_third_party_payment_enabled: true
      // })
    } catch (error) {

    }
  },
  async depositCrypto({ _ }, data) {
    try {
      const response = await this.$axios.post(`deposits/crypto`, data)
      return response.data
    } catch (error) {
      return false;
    }
  },
  async createDepositThirdParty({ commit }, data) {
    const windowLink = window.open()
    try {
      const response = await this.$axios.post(`deposits/third-party`, data)
      commit('setTempData', response.data)
      windowLink.location = getThirdPartyPaymentLinkByProvider(response.data.tp_payment_link, response.data.tp_gateway_code, this.$router)
      return response.data
    } catch (error) {
      windowLink.close();
      return false;
    }
  },
  async getCompanyCryptoWallet({ commit }, params) {
    try {
      const response = await this.$axios.get(`/company-crypto-wallet/deposit/settings`, { params })
      commit('setCompanyCryptoWallet', response.data)
      return response.data;
    } catch (error) {
      return false;
    }
  }
}

// Mutations
export const mutations = {
  setTempData: (state, data) => {
    state.tempData = data
  },
  setThirdPartyPayments: (state, data) => {
    state.thirdPartyPayments = data
  },
  depositFlow: (state, data) => {
    state.depositFlow = data;
  },
  checkExist: (state, data) => {
    state.checkExist = data
  },
  checkDeposit: (state, data) => {
    state.checkDeposit = data
  },
  setCompanyCryptoWallet: (state, data) => {
    state.companyCryptoWallet = data
  }
}

// Function to generate payment links for different payment providers
const paymentLinkProviders = {
  third_party_papaya_pay: (link, router) => `${link}?redirectionUrl=${window.location.origin}${router.currentRoute.fullPath}`,
};

const getThirdPartyPaymentLinkByProvider = (link, provider, router) => {
  const generateLink = paymentLinkProviders[provider];
  return generateLink ? generateLink(link, router) : link;
};
